
import state from './modulePermohonanState.js'
import mutations from './modulePermohonanMutations.js'
import actions from './modulePermohonanActions.js'
import getters from './modulePermohonanGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
