
<template>
  <div>
    <h5 class="pt-2"></h5>
    <template v-if="permohonanList.length > 0">
      <div id="permohonan-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
        <!--
        <vs-sidebar class="items-no-padding" parent="#permohonan-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isSidebarFilterActive">
            <component :is="scrollbarTag" class="permohonan-scroll-area" :settings="settings" :key="$vs.rtl">
                <permohonan-filters @closeSidebar="toggleFilterSidebar"
                :permohonanFilter="permohonanFilter"
                :Search="search"
                :dateMulai="mulai"
                :dateSampai="sampai"></permohonan-filters>
            </component>
        </vs-sidebar>
        -->

        <div :class="{'sidebar-spacer': clickNotClose}"  id="permohonan-load" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content vs-con-loading__container">

          <div class="d-theme-dark-bg flex items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light ">
            
            <!-- TOGGLE SIDEBAR BUTTON 
            <feather-icon class="md:inline-flex lg:hidden ml-2 cursor-pointer" icon="MenuIcon" @click.stop="toggleFilterSidebar(true)" />
            -->

            <!-- SEARCH BAR -->
            <vs-input icon-no-border size="large" placeholder="Search..." v-model="search" v-on:keyup.enter="filterData()" class="vs-input-no-border vs-input-no-shdow-focus w-full" />
            
            <vs-button class="float-right mr-2" size="small" icon-pack="feather" icon="icon-search" @click.stop="filterData()"></vs-button>
        </div>

        <!-- PERMOHONAN LIST -->
        <component :is="scrollbarTag" id="permohonan-load" class="permohonan-scroll-area" :settings="settings" ref="permohonanListPS" :key="$vs.rtl">
            <transition-group name="list-enter-up" tag="ul" appear>
              <li
                class="cursor-pointer"
                v-for="(permohonan, index) in permohonanList"
                :key="index"
                :style="[{transitionDelay: (index * 0.1) + 's'}]"
                @click="viewUpdateSidebar(permohonan)">
                <permohonan-item 
                  :data="permohonan" 
                />
              </li>
            </transition-group>
        </component>
        <!-- /PERMOHONAN LIST -->

        <div class="flex items-center justify-end py-2 md:px-8 px-4">
          <div class="dropdown-button-container">
            <vs-button class="btnx" type="border" size="small">Halaman</vs-button>

            <vs-dropdown>
              <vs-button class="btn-drop" type="filled" size="small">{{permohonanCurrentPage}}</vs-button>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="p in permohonanPage" :key="p" @click.stop="nextData(p)" :disabled="p === permohonanCurrentPage">
                  {{ p }}
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <!--
          <div class="flex">
            <flat-pickr format="yyyy-MM-dd" :config="configFromdateTimePicker" v-model="mulai" placeholder="Mulai" @on-change="onFromChange" class="mr-4" style="width:100px;height: 32px;"/>
            <flat-pickr format="yyyy-MM-dd" :config="configTodateTimePicker" v-model="sampai" placeholder="Sampai" @on-change="onToChange" class="mr-2" style="width:100px;height: 32px;"/>
            <feather-icon @click="filterData" icon="ArrowRightIcon" class="cursor-pointer" svg-classes="h-7 w-7"></feather-icon>
          </div>
          <div class="flex items-center">
            <div class="dropdown-button-container">
              <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                <feather-icon icon="CodeIcon" class="cursor-pointer" svg-classes="h-7 w-7"></feather-icon>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-for="p in permohonanPage" :key="p" @click.stop="nextData(p)" :disabled="p === permohonanCurrentPage">
                    {{ p }}
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>


            <feather-icon icon="PlusIcon" class="cursor-pointer ml-5" svg-classes="h-7 w-7" @click="addUpdateSidebar()"></feather-icon>
          </div>
          -->
        </div>
      </div>

      <permohonan-update 
        :isUpdateActive="isUpdateActive"
        @closeUpdate="closeUpdateSidebar"
        :data="dataPermohonan">
      </permohonan-update>
      </div>
    </template>
    <template v-else>
      <div class="h-screen-content flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/graphic-6.png" alt="graphic-not-authorized" class="mx-auto mb-base">
            <p class="sm:mx-0 mx-4 d-theme-heading-color text-2xl"> Belum ada permohonan</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import modulePermohonan          from '@/stores/permohonan/modulePermohonan.js'
import PermohonanItem            from './PermohonanItem.vue'
import PermohonanFilters         from './PermohonanFilters.vue'
import PermohonanUpdate          from './PermohonanUpdate.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr          from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    PermohonanItem,
    PermohonanFilters,
    PermohonanUpdate,
    VuePerfectScrollbar,
    flatPickr
  },
  data () {
    return {
      dataPermohonan       : {},
      isAccountAddActive   : false,
      currFilter           : '',
      clickNotClose        : true,
      displayPrompt        : false,
      isSidebarFilterActive: true,

      isUpdateActive       : false,

      settings : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },

      configFromdateTimePicker: {
        minDate: null,
        maxDate: null
      },
      configTodateTimePicker: {
        minDate: null
      },
      changeMulai: false,

      // Param
      search: '',
      mulai: null,
      sampai: null,
    }
  },
  watch: {
    isAccountAddActive (value) {
      if (!value) this.showThread = false
    },
    isUpdateActive (value) {
      if (!value) this.showThread = false
    },
    permohonanFilter () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#permohonan-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.permohonanListPS.$el || this.$refs.permohonanListPS
      scroll_el.scrollTop = 0
      const obj = {
        type: 'permohonan',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        urutan: 'desc',
        jumlah: 10,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai
      }
      this.$store.commit('permohonan/SET_NULL_TASKS')
      this.$store.dispatch('permohonan/fetchPermohonan', obj)
      if (this.$store.state.permohonan.permohonans.length > 0 || this.$store.state.permohonan.permohonans.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#permohonan-load > .con-vs-loading')
        }, 2000)
      }
      this.toggleFilterSidebar()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    permohonanFilter ()   { return this.$route.params.filter },
    permohonanList ()     { return this.$store.state.permohonan.permohonans },
    permohonanTags () {
      return this.$store.state.permohonan.Tags
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth ()  { return this.$store.state.windowWidth },
    permohonanTotal () {
      return this.$store.state.permohonan.total
    },
    permohonanTotalSatu () {
      return this.$store.state.permohonan.total + 1
    },
    permohonanLast () {
      return this.$store.state.permohonan.last
    },
    permohonanPerPage () {
      return this.$store.state.permohonan.per_page
    },
    permohonanCurrentPage () {
      return this.$store.state.permohonan.current_page
    },
    permohonanPage () {
      const page = []
      const number = this.$store.state.permohonan.total / this.$store.state.permohonan.per_page
      var i
      for (i=0; i < number; i++) {
        page.push(i+1)
      }
      return page
    },
    permohonanTotalPage () {
      let number = this.$store.state.permohonan.total / this.$store.state.permohonan.per_page
      if (number < 1) {
        number = 1
      }
      return number.toFixed(0)
    }
  },
  created () {
    this.$vs.loading()
    this.$store.registerModule('permohonan', modulePermohonan)
    this.setSidebarWidth()

    const obj = {
      type: 'berkas',
      filter_type: 'permohonan',
      filter: this.$route.params.filter,
      urutan:'desc',
      jumlah: 10
    }
    // Fetch
    this.$store.dispatch('permohonan/fetchPermohonan', obj)
    //this.$store.dispatch('permohonan/fetchKategori', 'berkas')
    if (this.$store.state.permohonan.permohonans.length > 0 || this.$store.state.permohonan.permohonans.length === 0) {
      setTimeout(() => {
        this.$vs.loading.close()
      }, 2000)
    }
  },
  beforeUpdate () {
    this.currFilter = this.$route.params.filter
  },
  beforeDestroy () {
    this.$store.unregisterModule('permohonan')
  },
  mounted () { 
  },
  methods: {
    onFromChange(selectedDates, dateStr) {
      this.changeMulai = true
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
    },
    onToChange(selectedDates, dateStr) {
      if (this.changeMulai != false) {
        this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Pilih tanggal mulai',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isSidebarFilterActive = this.clickNotClose = false
      } else {
        this.isSidebarFilterActive = this.clickNotClose = true
      }
    },
    toggleFilterSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isSidebarFilterActive = value
    },
    addUpdateSidebar() {
      this.dataPermohonan = {}
      this.isUpdateActive = true
    },
    viewUpdateSidebar(data) {
      this.dataPermohonan = data
      this.isUpdateActive = true
    },
    closeUpdateSidebar () {
      this.isUpdateActive = false
    },
    nextData (page) {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#permohonan-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.permohonanListPS.$el || this.$refs.permohonanListPS
      scroll_el.scrollTop = 0
      
      const obj = {
        type: 'permohonan',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        urutan: 'desc',
        jumlah: 10,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai,
        page: page
      }
      this.$store.commit('permohonan/SET_NULL_TASKS')
      this.$store.dispatch('permohonan/fetchPermohonan', obj)
      if (this.$store.state.permohonan.permohonans.length > 0 || this.$store.state.permohonan.permohonans.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#permohonan-load > .con-vs-loading')
        }, 2000)
      }
    },
    filterData () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#permohonan-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.permohonanListPS.$el || this.$refs.permohonanListPS
      scroll_el.scrollTop = 0

      this.$store.commit('permohonan/', this.$route.params.filter)
      const obj = {
        type: 'permohonan',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        urutan: 'desc',
        jumlah: 10,
        search: this.search,
        dateMulai: this.mulai,
        dateSampai: this.sampai
      }

      this.$store.commit('permohonan/SET_NULL_TASKS')
      this.$store.dispatch('permohonan/fetchPermohonan', obj)
      if (this.$store.state.permohonan.permohonans.length > 0 || this.$store.state.permohonan.permohonans.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#permohonan-load > .con-vs-loading')
        }, 2000)
      }
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/permohonan.scss";
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
  }
  .vs-button {
    padding: 0.5rem 1.5rem !important;
  }
}
.total-slider {
  min-width: unset;
}
.h-screen-content {
    height: 65vh !important;
}
</style>
