
export default {
  permohonans: [],
  permohonanTags: [],
  permohonanFilter: null,
  permohonanKategoris: [],
  permohonanAlbums: [],
  total: null,
  last: null,
  per_page: null,
  current_page: null
}
