<template>
  <div>
    <vs-sidebar click-not-close parent="#permohonan-app" :hidden-background="true" class="full-vs-sidebar permohonan-view-sidebar items-no-padding" v-model="isUpdateActive" position-right id="permohonan-update">

      <div class="flex flex-wrap items-center justify-between md:px-8 px-6 py-2 d-theme-dark-bg permohonan-header bg-primary">
        <div class="flex">
          <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="closeUpdateView" class="cursor-pointer mr-4" svg-classes="text-white w-6 h-6"></feather-icon>
          <h5 class="text-white pt-1">{{ Object.entries(this.data).length === 0 ? "New" : "View" }} Permohonan</h5>
        </div>

        <div class="flex items-center">
          <div v-if="Object.entries(this.data).length != 0">
            <div class="flex items-center" v-if="filter != 'trash'">
              <!--
              <vs-switch class="mr-2 pt-1" color="dark" v-model="disabled" v-can="'E'"/>
              -->
              <vs-button class="" color="danger" size="small" icon-pack="feather" icon="icon-trash" @click="hapusPermohonanConfirm()" v-can="'D'">Trash</vs-button>
            </div>
            <div class="flex" v-else>
              <vs-button class="" color="warning" size="small" icon-pack="feather" icon="icon-refresh-ccw" @click="restorePermohonanConfirm()" v-can="'D'">Restore</vs-button>
            </div>
          </div>
        </div>
      </div>
      
      <component class="scroll-area-permohonan-content px-5" :settings="settings" :is="scrollbarTag" key="1">
        
        <vx-card title-color="primary" title="Formulir data digital" class="mb-base mt-base">
          <div class="vx-row">
              <div class="vx-col w-full mt-4">
                <vs-input label="Nama Pemilik Sertipikat" v-model="dataNama" class="w-full"  v-validate="'required'" name="Nama"/>
                <span class="text-danger text-sm" v-show="errors.has('Nama')">{{ errors.first('Nama') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <vs-input label="Nomor Sertipikat" v-model="dataNomor" class="w-full" v-validate="'required'" name="Nomor"/>
                <span class="text-danger text-sm" v-show="errors.has('Nomor')">{{ errors.first('Nomor') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <small class="date-label">Umur</small>
                <vx-input-group>
                    <vs-input v-model="dataUmur" v-validate="'max:2|numeric|required'" name="Umur"/>
                    <template slot="append">
                      <div class="append-text bg-primary">
                      <span>Tahun</span>
                      </div>
                    </template>
                </vx-input-group>
                <span class="text-danger text-sm" v-show="errors.has('Umur')">{{ errors.first('Umur') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <vs-input label="Pekerjaan" v-model="dataPekerjaan" class="w-full" v-validate="'required'" name="Pekerjaan"/>
                <span class="text-danger text-sm" v-show="errors.has('Pekerjaan')">{{ errors.first('Pekerjaan') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <vs-input label="No. KTP" v-model="dataKTP" class="w-full" v-validate="'digits:16|numeric|required'" name="KTP"/>
                <span class="text-danger text-sm" v-show="errors.has('KTP')">{{ errors.first('KTP') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <vs-textarea label="Alamat" v-model="dataAlamat" class="w-full" v-validate="'required'" name="Alamat"/>
                <span class="text-danger text-sm" v-show="errors.has('Alamat')">{{ errors.first('Alamat') }}</span>
              </div>

              <div class="vx-col w-full mt-4 text-right flex justify-end">
                <vs-switch v-model="dataKuasa">
                    <span slot="on">Dikuasakan</span>
                    <span slot="off">Tidak dikuasakan</span>
                </vs-switch>
              </div>
              
          </div>
        </vx-card>

        <vx-card title-color="primary" v-if="dataKuasa" class="mb-base" title="Data kuasa">
          <div class="vx-row">
              <div class="vx-col w-full mt-4">
                <vs-input label="Nama" v-model="dataKuasaNama" class="w-full"  v-validate="'required'" name="Kuasa Nama"/>
                <span class="text-danger text-sm" v-show="errors.has('Kuasa Nama')">{{ errors.first('Kuasa Nama') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <small class="date-label">Umur</small>
                <vx-input-group>
                    <vs-input v-model="dataKuasaUmur" v-validate="'max:2|numeric|required'" name="Kuasa Umur"/>
                    <template slot="append">
                      <div class="append-text bg-primary">
                      <span>Tahun</span>
                      </div>
                    </template>
                </vx-input-group>
                <span class="text-danger text-sm" v-show="errors.has('Kuasa Umur')">{{ errors.first('Kuasa Umur') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <vs-input label="Pekerjaan" v-model="dataKuasaPekerjaan" class="w-full" v-validate="'required'" name="Kuasa Pekerjaan"/>
                <span class="text-danger text-sm" v-show="errors.has('Kuasa Pekerjaan')">{{ errors.first('Kuasa Pekerjaan') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <vs-input label="No. KTP" v-model="dataKuasaKTP" class="w-full" v-validate="'digits:16|numeric|required'" name="Kuasa KTP"/>
                <span class="text-danger text-sm" v-show="errors.has('Kuasa KTP')">{{ errors.first('Kuasa KTP') }}</span>
              </div>
              <div class="vx-col w-full mt-4">
                <vs-textarea label="Alamat" v-model="dataKuasaAlamat" class="w-full" v-validate="'required'" name="Kuasa Alamat"/>
                <span class="text-danger text-sm" v-show="errors.has('Kuasa Alamat')">{{ errors.first('Kuasa Alamat') }}</span>
              </div>
          </div>
        </vx-card>
        
        <vx-card title-color="primary" title="Bidang tanah terletak" class="mb-base">
          <div class="vx-row">
              <div class="vx-col w-full mt-4">
                <vs-select
                label="Kecamatan"
                v-model="dataKecamatan"
                v-validate="'required'"
                class="w-full"
                @change="mountKelurahan()"
                @click.prevent="changeKecamatan()"
                >
                    <vs-select-item value="0" text="Pilih"/>
                    <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKecamatan"/>
                </vs-select>
              </div>
              <div class="vx-col w-full mt-4">
                <vs-select
                label="Kelurahan"
                v-model="dataKelurahan"
                v-validate="'required'"
                class="w-full"
                @change="pilihKelurahan()"
                @click.prevent="changeKelurahan()"
                >
                    <vs-select-item value="0" text="Pilih"/>
                    <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKelurahan"/>
                </vs-select>
              </div>
          </div>
        </vx-card>

      </component>

      <div class="flex flex-wrap items-center justify-between py-2 md:px-8 px-4" slot="footer">
        <vs-button v-if="filter != 'trash'" class="" :disabled="!isFormValid" @click="updateData()" color="primary">SAVE DATA</vs-button>
        <!--
        <vs-button type="border" color="warning" @click="toJadwal()">JADWAL</vs-button>
        -->
      </div>

      <vs-prompt
        color="danger"
        title="Konfirmasi"
        @accept="hapusPermohonanAccept"
        :active.sync="hapusPermohonan">
        <div class="con-exemple-prompt">
          Yakin ingin menghapus permohonan {{ dataNama }}
        </div>
      </vs-prompt>

      <vs-prompt
        color="warning"
        title="Konfirmasi"
        @accept="restorePermohonanAccept"
        :active.sync="restorePermohonan">
        <div class="con-exemple-prompt">
          Yakin ingin me-restore permohonan {{ dataNama }}
        </div>
      </vs-prompt>
    </vs-sidebar>
  </div>
</template>
<script>
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
export default{
  props: {
    isUpdateActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      // Set
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.50
        },
        disabled: false,

      // Init
        dataNomor: '',
        dataNama: '',
        dataUmur: '',
        dataPekerjaan: '',
        dataKTP: '',
        dataAlamat: '',
        // Kuasa
        dataKuasa: false,
        dataKuasaNama: '',
        dataKuasaUmur: '',
        dataKuasaPekerjaan: '',
        dataKuasaKTP: '',
        dataKuasaAlamat: '',
        // Lokasi
        listKecamatan: [],
        dataKecamatan: 0,
        dataKecamatanNama: '',
        dataChangeKecamatan: false,
        listKelurahan: [],
        dataKelurahan: 0,
        dataKelurahanNama: '',
        dataChangeKelurahan: false,

        dataLayanan: null,
        dataLayananParent: {},
      
      hapusPermohonan: false,
      restorePermohonan: false
    }
  },
  watch: {
    isUpdateActive (value) {
      if (value) {
        if (Object.entries(this.data).length === 0) {
          this.disabled = false
          this.initValues()
          this.$validator.reset()
        } else {
          //this.disabled = true
          const { id, task_title, task_status, comment_status, task_password, task_type, task_parent, 

          nomor, nama, umur, pekerjaan, ktp, alamat, kuasa, kuasa_nama, kuasa_umur, kuasa_pekerjaan, kuasa_ktp, kuasa_alamat, kecamatan_id, kecamatan_name, kelurahan_id, kelurahan_name,

          layanan  } = JSON.parse(JSON.stringify(this.data))

          this.dataLayanan = layanan
          //this.dataLayananParent = parent
          // Task
          this.dataTaskID = id
          this.dataTitle = task_title
          this.dataStatus = task_status
          this.dataCommentStatus = comment_status
          this.dataPassword = task_password
          this.dataType = task_type
          this.dataParent = task_parent

          // Meta
            this.dataNomor = nomor
            this.dataNama = nama
            this.dataUmur = umur
            this.dataPekerjaan = pekerjaan
            this.dataKTP = ktp
            this.dataAlamat = alamat
          // Kuasa
            let kuasaStatus = false
            if (kuasa === 'true') {
                kuasaStatus = true
            }
            this.dataKuasa = kuasaStatus
            this.dataKuasaNama = kuasa_nama
            this.dataKuasaUmur = kuasa_umur
            this.dataKuasaPekerjaan = kuasa_pekerjaan
            this.dataKuasaKTP = kuasa_ktp
            this.dataKuasaAlamat = kuasa_alamat
          // Lokasi
            this.dataKecamatan = kecamatan_id
            this.dataKecamatanNama = kecamatan_name
            this.dataKelurahan = kelurahan_id
            this.dataKelurahanNama = kelurahan_name

          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
          this.axios.get('/term-parent/'+this.dataKecamatan, configs)
          .then((response) => {
            let {data} = response.data
            this.listKelurahan = data
          })
          .catch((error) => {
            let data = error.data
            this.listKelurahan = data
          })
        }
      } else { return }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
    isFormValid () { 
      let kuasa = true
      if (this.dataKuasa == true && 
        this.dataKuasaNama != '' &&
        this.dataKuasaUmur != '' &&
        this.dataKuasaPekerjaan != '' &&
        this.dataKuasaKTP != '' &&
        this.dataKuasaAlamat != '') {
            kuasa = true
      } else if (this.dataKuasa == false) {
        kuasa = true
      } else {
        kuasa = false
      }
      return kuasa && 
      !this.errors.any() &&
      this.dataNomor != '' &&
      this.dataNama != '' &&
      this.dataUmur != '' &&
      this.dataPekerjaan != '' &&
      this.dataKTP != '' &&
      this.dataAlamat != ''
    },
    filter () {
      return this.$route.params.filter
    }
  },
  created () {
    this.$store.commit('navbarUpdateStatus', false)
  },
  methods: {
    updateData () {
        this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          let formData = new FormData()

          formData.set('id', this.dataTaskID)
          formData.set('title', this.dataNama+' - '+this.dataLayanan.task_title)
          formData.set('status', 'publish')
          formData.set('comment_status', 'open')
          formData.set('password', null)
          formData.set('parent', 0)
          formData.set('type', 'berkas')
          formData.set('comment_count', 0)

          let meta = {
              nomor: this.dataNomor,
              nama: this.dataNama,
              umur: this.dataUmur,
              pekerjaan: this.dataPekerjaan,
              ktp: this.dataKTP,
              alamat: this.dataAlamat,
              // Kuasa
              kuasa : this.dataKuasa,
              kuasa_nama : this.dataKuasaNama,
              kuasa_umur : this.dataKuasaUmur,
              kuasa_pekerjaan : this.dataKuasaPekerjaan,
              kuasa_ktp : this.dataKuasaKTP,
              kuasa_alamat : this.dataKuasaAlamat,
              // Lokasi
              kecamatan_id: this.dataKecamatan,
              kecamatan_name: this.dataKecamatanNama,
              kelurahan_id: this.dataKelurahan,
              kelurahan_name: this.dataKelurahanNama, 

              berkas : this.dataLayanan.id,
              berkas_name : this.dataLayanan.task_title
          }
          formData.set('meta', JSON.stringify(meta))

          formData.set('urutan', 'desc')
          this.$store.dispatch('permohonan/updateData', formData)
          .then((response) => {
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Berhasil',
                color  : 'success',
                text  : response.message,
              })
              this.initValues()
              this.closeUpdateView()
              this.initValues()
          })
          .catch(error => {
              this.$vs.loading.close()
              let {data} = error.response
              this.$vs.notify({
                title: 'Error',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
          })
        }
        })
    },
    hapusPermohonanConfirm () {
      this.hapusPermohonan = true
    },
    hapusPermohonanAccept () {
      if (Object.entries(this.data).length > 0) {
        this.$vs.loading()
        const obj = {
          id : this.dataPermohonansID,
          parent : this.dataParent
        }
        this.$store.dispatch('permohonan/removePermohonan', obj)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          this.initValues()
          this.closeUpdateView()
          this.initValues()
        })
      }
      this.hapusPermohonan = false
    },
    restorePermohonanConfirm () {
      this.restorePermohonan = true
    },
    restorePermohonanAccept () {
      if (Object.entries(this.data).length > 0) {
        this.$vs.loading()
        const obj = {
          id : this.dataPermohonansID,
          parent : this.dataParent
        }
        this.$store.dispatch('permohonan/restorePermohonan', obj)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          this.initValues()
          this.closeUpdateView()
          this.initValues()
        })
      }
      this.restorePermohonan = false
    },
    initValues() {
      this.dataNomor = ''
      this.dataNama = ''
      this.dataUmur = ''
      this.dataPekerjaan = ''
      this.dataKTP = ''
      this.dataAlamat = ''
      // Kuasa
      this.dataKuasa = false
      this.dataKuasaNama = ''
      this.dataKuasaUmur = ''
      this.dataKuasaPekerjaan = ''
      this.dataKuasaKTP = ''
      this.dataKuasaAlamat = ''
      // Lokasi
      this.listKecamatan = []
      this.dataKecamatan = 0
      this.dataKecamatanNama = ''
      this.dataChangeKecamatan = false
      this.listKelurahan = []
      this.dataKelurahan = 0
      this.dataKelurahanNama = ''
      this.dataChangeKelurahan = false

      this.dataLayanan = null
      this.dataLayananParent = {}
    },
    toJadwal () {
        let submit = this.data
        this.$router.push({
          name: "LayananJadwal",
          params: { submit }
        });
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    },
    closeUpdateView() {
      this.initValues()
      this.$emit('closeUpdate')
      this.initValues()
    },
    mountKecamatan () {
      let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
      this.axios.get('/term-list/kecamatan', configs)
      .then((response) => {
          let {data} = response.data
          this.listKecamatan = data
      })
      .catch((error) => {
          let data = error.data
          this.listKecamatan = data
      }) 
    },
    changeKecamatan () {
      this.dataChangeKecamatan = true
    },
    mountKelurahan () {
      if (this.dataChangeKecamatan) {
          const ItemIndex = this.listKecamatan.find((camat) => camat.id == this.dataKecamatan)
          this.dataKecamatanNama = ItemIndex.name
          let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
          this.axios.get('/term-parent/'+this.dataKecamatan, configs)
          .then((response) => {
            let {data} = response.data
            this.listKelurahan = data
          })
          .catch((error) => {
            let data = error.data
            this.listKelurahan = data
          }) 
      }
    },
    changeKelurahan () {
      this.dataChangeKelurahan = true
    },
    pilihKelurahan () {
      if (this.dataChangeKelurahan) {
          const ItemIndex = this.listKelurahan.find((lurah) => lurah.id == this.dataKelurahan)
          this.dataKelurahanNama = ItemIndex.name
      }
    }
  },
  mounted () {
    this.mountKecamatan()
  }
}
</script>
<style lang="scss" scoped>
.vs-switch {
  width: 8rem !important;
}
</style>