

<template>
  <div class="px-4 py-3 list-item-component">
    <div class="vx-row md:px-4">
      <div class="vx-col w-3/4 items-center">
        <div class="flex items-center mb-1">
          <div class="permohonan-tags mt-1 flex">
              {{ data.created_at }}
          </div>
        </div>
        <div class="flex items-center">
          <h4 class="permohonan-title items-center mb-1">
            {{ data.task_title }}
          </h4>
        </div>
        <div class="flex items-center mb-1">
          <div class="permohonan-tags mt-1 flex">
              No. {{ data.nomor }}
          </div>
        </div>
      </div>

      <div class="vx-col w-1/4 flex items-center justify-end">
        <div class="flex items-center justify-end">
          <vs-chip :color="statusGetColor()">{{ status }}</vs-chip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      status : 'Proses'
    }
  },
  computed: {
    permohonanLabelText () {
      return (text) => {
        const tags = this.$store.state.permohonan.permohonanTags
        return tags.find((tag) => {
          return tag.slug === text
        }).name
      }
    },
    permohonanLabelColor () {
      return (label) => {
        const tags = this.$store.state.permohonan.permohonanTags
        return tags.find((tag) => {
          return tag.slug === label
        }).color
      }
    }
  },
  methods: {
    statusGetColor () {     
      const ItemIndex = this.data.relation.alur.find((alur) => alur.object_status === 'false')
      if (ItemIndex) {
        return 'warning'
      } else {
        this.status = 'Selesai'
        return 'success'
      }
    }
  }
}
</script>
